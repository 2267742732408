import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import noaWeb from "../../Assets/Projects/noatalelWeb.png";
import simulator from "../../Assets/Projects/simulator.png";
import foodApp from "../../Assets/Projects/foodApp.png";
import pongGame from "../../Assets/Projects/pongGame.png";
import FashionWeb from "../../Assets/Projects/FashionWeb.png";
import TravelApp from "../../Assets/Projects/travelApp.jpg";
import Bookstore from "../../Assets/Projects/bookstore.png";
import ChargeMonitor from "../../Assets/Projects/ChargeMonitor.webp";
function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Projects for <strong className="purple">clients </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are some projects I've worked on as a freelancer for clients.
        </p>
        <Row>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ChargeMonitor}
              isBlog={true}
              title="ChargeMonitor"
              description="The application checks the availability of electric vehicle charging stations via an API, fetching data every 5 minutes. If the availability changes, it sends an email notification. The data is stored in Prometheus for real-time monitoring, and a Flask server serves the metrics at the /metrics endpoint. The system runs with two threads: one for Flask and one for monitoring the stations and sending alerts."
              ghLink="https://github.com/amitha511/empty-spot-notifier"
              h={250}
              m={"12%"}
              w={300}
            />
          </Col>
            <Col md={4} className="project-card">
            <ProjectCard
                imgPath={noaWeb}
                isBlog={false}
                title="Cosmetic Website"
                description="A dynamic website for a cosmetics business using React and Node.js. The site contains a very impressive product catalog, the implementation of an efficient shopping cart system, the presentation of the expertise, services and portfolio of the business, and many other features."
                ghLink="https://noatal-el.com/"
            />
            </Col>
        </Row>
      </Container>
      <Container>
        <h1 className="project-heading">
          <strong className="purple"> Personal </strong>projects
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={TravelApp}
              isBlog={true}
              h={250}
              m={"30%"}
              w={150}
              title="Travel App"
              description="Developed a travel schedule app designed to create personalized itineraries for users. The application utilizes the Google API to gather attraction data, ensuring an enriching travel experience. The project encompassed several key features:
user authentication, custom trip algorithm, attraction Sorting, travel Page and more.
Technologies used: React Native, Expo, Bootstrap, NodeJS and MongoDB."
              ghLink="https://github.com/amitha511/react-native-travel-app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={foodApp}
              isBlog={true}
              h={250}
              m={"4%"}
              w={250}
              title="Foodies App"
              description="
Android application development with MVC architecture. Using Google's design guidelines. User authentication using the Firebase authentication server. Remote storage on the Firebase database server of objects and images. Implement image and object caching using Room-SQLite (local database). Using fragments, navigation graph and passing parameters using safeArgs."
              ghLink="https://github.com/amitha511/foodies_app"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={FashionWeb}
              isBlog={true}
              title="Fashion Shop"
              description="Website development with MVC architecture, contains development of CRUD operations for product management with administrator authentication.
Post advertising application on Facebook via Facebook API, impressive user interface catalog with filtering options and more
property. Technologies used: JavaScript, NodeJS and MongoDB for backend development."
              ghLink="https://github.com/amitha511/fashion_store_website"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Bookstore}
              isBlog={true}
              title="Bookstore Website"
              description="End-to-end development of an interactive website for a bookstore.
The project introduced an array of advanced functionalities aimed at optimizing user engagement and operational efficiency.
User interface catalog with diverse filter options, manager authentication system, dynamic CRUD operations, in-depth shopping analysis on the manager page.
Technologies used: React, Bootstrap, Firebase, Socket.IO, NodeJS and MongoDB."
              ghLink="https://github.com/amitha511/pong-game"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={simulator}
              isBlog={true}
              title="flight Simulator"
              description="The javaFX-flightSimulator it's a joystick that connects remotely to a flight simulator FlightGear, The joystick controls the flight of the aircraft."
              ghLink="https://github.com/amitha511/flight-simulator"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pongGame}
              isBlog={true}
              title="Pong Game"
              description="A simple pong game for multiplayer."
              ghLink="https://github.com/amitha511/pong-game"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
